import React from "react"
import Header from "./header"
import "./style.scss"


const Layout = ({ children }) => {
  return (
    
    <div id="main">
      <Header />
      <div>{children}</div>
      
    </div>
 
  )
}
export default Layout
